
























import { Models } from '@mtap-smartcity/lib-api';
import { Component, Vue, Prop } from 'vue-property-decorator';
import parametersMap from '@/constants/device_parameters_map';

interface DeviceProperty {
  name: string,
  displayName: string,
  value: string | number | Array<number>,
  type: 'string' | 'number' | 'array'
}

@Component
export default class DevicePropertiesList extends Vue {
  @Prop(Object) readonly deviceParameters!:
    Models.Devices.LampIotParameters |
    Models.Devices.LampIqrfParameters |
    Models.Devices.LampVeParameters |
    Models.Devices.GatewayVeParameters |
    Models.Devices.BridgeParameters |
    null;

  @Prop(String) readonly tab!: 'device' | 'powerSupply' | 'controller';

  @Prop(String) readonly deviceType!: Models.Constants.DeviceType;

  @Prop(String) readonly controllerType!: Models.Constants.ControllerType | null;

  @Prop(Boolean) readonly disabled!: boolean;

  isFormValid = true;

  formRules = {
    longitude: [(v) => (v >= -180 && v <= 180) || !v || 'Podaj wartość między -180 a 180'],
    latitude: [(v) => (v >= -90 && v <= 90) || !v || 'Podaj wartość między -90 a 90'],
    subnetId: [(v) => (v >= 0 && v <= 255) || !v || 'Podaj wartość między 0 a 255'],
    netId: [(v) => (v >= 0 && v <= 255) || !v || 'Podaj wartość między 0 a 255'],
    daliShortAddress: [(v) => (v >= 0 && v <= 63) || !v || 'Podaj wartość między 0 a 63'],
    luminaires: [(v) => (v >= 1 && v <= 4) || !v || 'Podaj wartość między 1 a 4']
  };

  get parameters(): DeviceProperty[] {
    const params = this.deviceParameters;
    if (!params) return [];
    const parametersToMap = this.deviceType === 'lamp' && this.tab === 'controller'
      ? parametersMap[this.deviceType][this.tab][this.controllerType!]
      : parametersMap[this.deviceType][this.tab];
    const result = (parametersToMap as any[]).map((p) => {
      if (p.dataType === 'date' && params[p.name]) {
        const value = (new Date(params[p.name])).toISOString()
          .substr(0, 10);
        const displayName = this.$t(`deviceParameters.${p.name}`);
        return {
          ...p,
          value,
          displayName
        };
      }
      const value = params[p.name] ?? null;
      const displayName = this.$t(`deviceParameters.${p.name}`);
      return {
        ...p,
        value,
        displayName
      };
    });
    return result;
  }

  get rules() {
    return (paramName) => {
      const rule = this.formRules[paramName] ?? [];
      return rule;
    };
  }

  suffix(propName: string) {
    let suffix = '';
    switch (propName) {
      case 'serviceLife':
        suffix = this.$tc('main.year', 2);
        break;
      case 'luminousEfficiency':
        suffix = 'lm/W';
        break;
      case 'maxPower':
        suffix = 'W';
        break;
      default:
        break;
    }
    return suffix;
  }

  updateProperty(value, name) {
    const newVal = name === 'installationDate' ? new Date(value).toISOString() : value;
    this.$emit('update:deviceParameters', {
      name,
      value: newVal
    });
  }
}
