























































































































































import {
  Component, Vue, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppState } from '@/store/modules/app/types';
import { Models } from '@mtap-smartcity/lib-api';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import { dateTimeToStr } from '@/utils/time_format';
import { TelemetryGetter, TelemetryGetterType } from '@/store/modules/telemetry/types';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { hasCoordinates, isControlCabinetTelemetry } from '@/utils/type_check';

const app = namespace('app');
const devices = namespace('devices');
const telemetry = namespace('telemetry');

@Component
export default class DevicesListItem extends Vue {
  @Prop(Object) readonly device!: Models.Devices.Device;

  @Prop(Array) readonly forbiddenNames!: Array<string>;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @devices.State
  selectedId!: DevicesState['selectedId'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @devices.Action(DevicesAction.UpdateDevice)
  updateDevice!: DevicesActionType['UPDATE_DEVICE'];

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  @devices.Action(DevicesAction.RecommissionDevice)
  recommissionDevice!: DevicesActionType['RECOMMISSION_DEVICE'];

  @devices.Action(DevicesAction.CreateDevice)
  createDevice!: DevicesActionType['CREATE_DEVICE'];

  @devices.Action(DevicesAction.FetchDevice)
  fetchDevice!: DevicesActionType['FETCH_DEVICE'];

  @devices.Action(DevicesAction.RemoveDevicePlaceholder)
  removePlaceholderDevice!: DevicesActionType['REMOVE_DEVICE_PLACEHOLDER'];

  deviceData: Models.Devices.Device = JSON.parse(JSON.stringify(this.device));

  editState: boolean = false;

  controllerType = {
    iothub: 'mTap',
    ve: 'VE',
    iqrf: 'IQRF',
  };

  selectItems = [
    { text: 'mTap', value: 'iothub' },
    { text: 'VE', value: 've' },
    { text: 'IQRF', value: 'iqrf' },
  ];

  intervalID: any;

  showNameTooltip: boolean = false;

  recommissionForbidden: boolean | null = this.device.uuid ? !!localStorage[this.device.uuid] : null;

  // Temporary 'any' type, typing has to be fixed to: telemetry or null
  get telemetryData(): any {
    if (!this.device) return null;
    return this.getLastTelemetry(this.device.object_id, this.device.device_type);
  }

  get isSelected() {
    return this.selectedId === this.device.id;
  }

  get isPlaceholderItem() {
    return !this.device.id;
  }

  get deviceIsToggledOn() {
    let result = false;
    if (!this.deviceIsOnline) {
      result = false;
    } else if (
      this.telemetryData
      && (
        this.telemetryData?.telemetry.set_duty > 0
        || this.telemetryData?.telemetry.setDuty > 0
      )
    ) {
      result = true;
    }
    return result;
  }

  get deviceIsBondedWithGateway() {
    let deviceIsBonded = false;
    deviceIsBonded = this.device.registrationStatus ?? false;
    return deviceIsBonded;
  }

  get deviceError() {
    if (this.telemetryData && this.telemetryData.telemetry.status > 1) {
      return this.telemetryData.telemetry.status;
    }
    return 0;
  }

  get deviceLastConnectionDate() {
    return this.telemetryData ? dateTimeToStr(this.telemetryData.timestamp) : null;
  }

  get deviceIsOnline() {
    const timestamp = this.telemetryData !== null ? new Date(this.telemetryData.timestamp) : null;
    const currentTime = new Date();
    const oneMinute: number = 60000;
    let allowedDelay: number = oneMinute * 1.5;
    if (this.device.device_type === 'gateway') allowedDelay = oneMinute * 2;
    if (
      !!this.telemetryData && isControlCabinetTelemetry(this.telemetryData)
    ) allowedDelay = oneMinute * 5.5;
    return (Math.abs(Number(timestamp) - Number(currentTime)) <= allowedDelay);
  }

  get deviceIsOnTheMap() {
    return hasCoordinates(this.device.parameters.device);
  }

  refreshWarningExists() {
    return !!window.onbeforeunload;
  }

  checkIfRecommissioningInProgress() {
    return setTimeout(() => {
      if (this.device.uuid) this.recommissionForbidden = !!localStorage[this.device.uuid];
      // eslint-disable-next-line no-unused-expressions
      this.recommissionForbidden
        ? window.onbeforeunload = () => ''
        : window.onbeforeunload = null;
    }, 0);
  }

  setCommisionInLocalStorage() {
    if (this.device.uuid) localStorage.setItem(`${this.device.uuid}`, 'commissioning');
  }

  removeCommisionFromLocalStorage() {
    if (this.device.uuid) localStorage.removeItem(`${this.device.uuid}`);
  }

  validateInputs() {
    return !!this.deviceData.object_id
      && !!this.deviceData.controller_type
      && !this.forbiddenNames.includes(this.deviceData.object_id);
  }

  showMarkerOnMap(markerId: number) {
    this.setSelectedMarkerId(null);
    this.setSelectedMarkerId(markerId);
  }

  discard() {
    // TODO
    this.editState = false;
    if (!this.device.id) {
      this.removePlaceholderDevice();
      this.showNameTooltip = false;
    }
  }

  accept() {
    const inputsAreValid = this.validateInputs();
    if (inputsAreValid) {
      this.createDevice(this.deviceData).finally(() => {
        this.editState = false;
        this.removePlaceholderDevice();
        this.showNameTooltip = false;
      });
    } else {
      this.showNameTooltip = true;
      focusOnNewItemNameInput();
    }
  }

  recommissionDeviceOnce() {
    this.setCommisionInLocalStorage();
    this.checkIfRecommissioningInProgress();
    this.recommissionDevice(this.device)
      .then(() => {
        const { timeHistoricals } = this.$store.state.app ? this.$store.state.app : null;
        setTimeout(async () => {
          await this.fetchDevice({ device: this.device, unixTime: timeHistoricals });
          this.removeCommisionFromLocalStorage();
          this.checkIfRecommissioningInProgress();
        }, 10000);
      })
      .catch(() => {
        this.removeCommisionFromLocalStorage();
        this.checkIfRecommissioningInProgress();
      });
  }

  mounted() {
    setTimeout(async () => {
      if (this.recommissionForbidden && !this.refreshWarningExists()) {
        this.removeCommisionFromLocalStorage();
      }
      if (this.recommissionForbidden) {
        const { timeHistoricals } = this.$store.state.app ? this.$store.state.app : null;
        await this.fetchDevice({ device: this.device, unixTime: timeHistoricals });
        this.checkIfRecommissioningInProgress();
      }
    }, 10000);
    if (this.isPlaceholderItem) {
      this.editState = true;
    }
  }

  beforeDestroy() {
    this.setSelectedMarkerId(null);
  }
}
